import * as signInTypes from "store/actions/auth";

export const initialState = {
  token: "",
  user: "",
  success: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.SIGN_IN:
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        success: action.data.success,
      };
    case signInTypes.ADD_USER:
      return {
        ...state,
        user: action.data,
      };
    case signInTypes.ADD_PROFILE:
      return {
        ...state,
        profileData: action.data,
      };
    case signInTypes.CHANGE_PROFILE:
      return {
        ...state,
        profileData: { ...state["profileData"], ...action["data"] },
      };
    case signInTypes.ADD_ITEM:
      return {
        ...state,
        itemId: action.data,
      };

    case signInTypes.SIGN_OUT:
      return {
        token: action.data,
      };
    // case signInTypes.REFRESH_TOKEN_AUTH:
    //   return {
    //     ...state,
    //     accessToken: action.data.accessToken,
    //     refreshToken: action.data.refreshToken
    //   };
    // case signInTypes.SIGN_OUT:
    //   return { ...initialState, sessionTimedOut: true };
    default:
      return state;
  }
};

export default authReducer;
