/** load redux state */
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    sessionStorage.removeItem('state');
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
/** save redux state */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const limitItemDescription = (description, delimiter) => {
  const descriptionSubstring = description?.substring(0, delimiter);

  return description?.length > 20
    ? `${descriptionSubstring?.slice(
        0,
        descriptionSubstring?.lastIndexOf(' ')
      )}...`
    : description;
};

export const saveAs = (blob, fileName) => {
  var elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

export const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl?.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr?.[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const objectToFormData = (object) => {
  const formData = new FormData();
  for (let key in object) {
    Array.isArray(object[key])
      ? object[key].forEach((value) => formData.append(key + '[]', value))
      : formData.append(key, object[key]);
  }

  return formData;
};

export const isFileImage = (file) => {
  return file && file['type'].split('/')[0] === 'image';
};

export const isPdfLink = (url) => {
  return url.slice(url.lastIndexOf('.') + 1) === 'pdf';
};

export const addCommasToNumber = (string) => Number(string).toLocaleString();

export const commaFormattedValue = (value) =>
  (Number(value.replace(/\D/g, '')) || '').toLocaleString();

export const removeSpecialCharacters = (string) =>
  string.replace(/[^\w\s]/gi, '').replace(/[A-Za-z]/g, '');

export const openWindowPopUP = (url) => {
  // var popupWindow = null;
  window.open(
    url,
    'popUpWindow',
    'height=300,width=700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
  );
};
export const closeWindowPopUp = () => {
  // var popupWindow = null;
  window.close();
};

export const splitNames = (name) =>
  name?.split(' ')?.map((name) => name?.toLowerCase());

export const checkNameMatch = (firstName, secondName) => {
  const firstNameArray = splitNames(firstName);
  const secondNameArray = splitNames(secondName);

  let matchCheck = 0;

  for (let name of firstNameArray) {
    if (secondNameArray.includes(name)) {
      matchCheck += 33;
    }
  }
  return matchCheck < 66 ? false : true;
};
