import * as signInTypes from "store/actions/toast";

export const initialState = {
  status: "",
  message: "",
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.DISPLAY_TOAST:
      return {
        ...state,
        status: action.data.status,
        message: action.data.message,
      };

    default:
      return state;
  }
};

export default toastReducer;
