import { lazy } from 'react';

const Home = lazy(() => import('pages/user/Home'));
const About = lazy(() => import('pages/user/About'));
const Careers = lazy(() => import('pages/user/Careers'));
const Contact = lazy(() => import('pages/user/Contact'));
const Faq = lazy(() => import('pages/user/Faq'));
const Terms = lazy(() => import('pages/user/Terms'));
const Privacy = lazy(() => import('pages/user/Privacy'));
const Checkout = lazy(() => import('pages/user/Checkout'));
const Ambassadors = lazy(() => import('pages/user/Ambassadors'));
const PaymentSuccess = lazy(() => import('pages/user/PaymentSuccess'));
const CancelPayment = lazy(() => import('pages/user/CancelPayment'));
const Blog = lazy(() => import('pages/user/blog/Blog'));
const BlogDetails = lazy(() => import('pages/user/blog/BlogDetails'));
const BlogPage = lazy(() => import('pages/user/blog/BlogPage'));
const ChooseAccount = lazy(() => import('pages/user/auth/ChooseAccount'));
const Login = lazy(() => import('pages/user/auth/Login'));
const AdminLogin = lazy(() => import('pages/admin/AdminLogin'));
const SignupIndividual = lazy(() => import('pages/user/auth/SignupIndividual'));
const SignupBusiness = lazy(() => import('pages/user/auth/SignupBusiness'));
const SignupAgent = lazy(() => import('pages/user/auth/SignupAgent'));
const ForgotPassword = lazy(() => import('pages/user/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/user/auth/ResetPassword'));
const PageNotFound = lazy(() => import('pages/user/PageNotFound'));
const P2P = lazy(() => import('pages/user/localUser/P2P'));
const ForeignHelpaP2P = lazy(() => import('pages/user/foreignUser/P2P'));
const LuStatus = lazy(() => import('pages/user/localUser/Status'));
const FuStatus = lazy(() => import('pages/user/foreignUser/Status'));
const HelpaPage = lazy(() => import('pages/user/proHelpa/HelpaPage'));
const Pay = lazy(() => import('pages/user/proHelpa/Pay'));
const ProhelpaLanding = lazy(() =>
  import('pages/user/proHelpa/ProhelpaLanding')
);
const Education = lazy(() => import('pages/user/products/Education'));
const Health = lazy(() => import('pages/user/products/Health'));
const CarPurchase = lazy(() => import('pages/user/products/CarPurchase'));
const Bills = lazy(() => import('pages/user/products/Bills'));
const Travel = lazy(() => import('pages/user/products/Travel'));
const Shopping = lazy(() => import('pages/user/products/Shopping'));
const Referral = lazy(() => import('pages/user/referral/Referral'));
const VerificationSuccess = lazy(() =>
  import('pages/user/auth/VerificationSuccess')
);
const RegistrationSuccess = lazy(() =>
  import('pages/user/auth/RegistrationSuccess')
);
const BusinessRegistrationSuccess = lazy(() =>
  import('pages/user/auth/BusinessRegistrationSuccess')
);
const PasswordresetSuccess = lazy(() =>
  import('pages/user/auth/PasswordResetSuccess')
);
const AccountVerification = lazy(() =>
  import('pages/user/AccountVerification')
);
const IdentityVerification = lazy(() =>
  import('pages/user/IdentityVerification')
);
const BusinessAccountVerification = lazy(() =>
  import('pages/user/BusinessAccountVerification')
);
const AgentAccountVerification = lazy(() =>
  import('pages/user/AgentAccountVerification')
);
const OnBoardingProhelpa = lazy(() => import('pages/user/onBoarding/Prohelpa'));
const OnBoardingHelpa = lazy(() => import('pages/user/onBoarding/Helpa'));
const OnBoarding = lazy(() => import('pages/user/foreignUser/OnBoarding'));
const UserOnboarding = lazy(() => import('pages/user/UserOnboarding'));
const Wallet = lazy(() => import('pages/user/wallet/Wallet'));
const Settings = lazy(() => import('pages/user/settings/Settings'));
const BusinessUserSettings = lazy(() =>
  import('pages/user/businessUserSettings/BusinessUserSettings')
);

const ReferralLanding = lazy(() =>
  import('pages/user/referral/ReferralLanding')
);

export const home = '/';
export const about = '/about';
export const careers = '/careers';
export const contact = '/contact';
export const faq = '/faq';
export const referralLanding = '/referralLanding';
export const terms = '/terms&conditions';
export const privacy = '/privacypolicy';
export const checkout = '/checkout';
export const ambassadors = '/ambassadors';
export const paymentSuccess = '/checkout/success';
export const cancelPayment = '/checkout/cancel';
export const blog = '/blog';
export const blogDetails = '/blog/:slug';
export const blogPage = '/pages/:slug';
export const helpaPage = '/prohelpa/helpaPage';
export const pay = '/prohelpa/pay/:slug';
export const prohelpaLanding = '/prohelpa';
export const education = '/products/education';
export const health = '/products/health';
export const carPurchase = '/products/carPurchase';
export const bills = '/products/bills';
export const travel = '/products/travel';
export const shopping = '/products/shopping';
export const chooseAccount = '/auth/chooseaccount';
export const forgotPassword = '/auth/forgotpassword';
export const resetPassword = '/auth/resetpassword';
export const login = '/auth/login';
export const adminLogin = '/auth/admin/login';
export const signupIndividual = '/auth/signup/individual';
export const signupBusiness = '/auth/signup/business';
export const signupAgent = '/auth/signup/agent';
export const verificationSuccess = '/auth/verification/success';
export const registrationSuccess = '/auth/registration/success';
export const businessRegistrationSuccess =
  '/auth/business/registration/success';
export const passwordresetSuccess = '/auth/passwordreset/success';
export const p2p = '/dashboard/p2p';
export const accountVerification =
  '/dashboard/verification/accountverification';
export const identityVerification =
  '/dashboard/verification/identityverification';
export const businessAccountVerification =
  '/dashboard/business/verification/accountverification';
export const agentAccountVerification =
  '/dashboard/agent/verification/accountverification';
export const onBoardingLocal = '/onboarding/local';
export const onBoardingProhelpa = '/onboarding/prohelpa';
export const onBoardingHelpa = '/onboarding/helpa';
export const onBoarding = '/foreignhelpa/onboarding';
export const foreignHelpaP2P = '/dashboard/foreignhelpa/p2p';
export const userOnboarding = '/user/onboarding';
export const wallet = '/dashboard/nairawallet';
export const settings = '/dashboard/settings';
export const businessUserSettings = '/dashboard/business/settings';
export const luStatus = '/dashboard/nigerianuser/status';
export const fuStatus = '/dashboard/foreignhelpa/status';
export const referral = '/dashboard/referral';
export const pageNotFound = '/pagenotfound';

export default [
  { path: home, component: Home },
  { path: about, component: About },
  { path: careers, component: Careers },
  { path: contact, component: Contact },
  { path: faq, component: Faq },
  { path: terms, component: Terms },
  { path: privacy, component: Privacy },
  { path: checkout, component: Checkout },
  { path: ambassadors, component: Ambassadors },
  { path: paymentSuccess, component: PaymentSuccess },
  { path: cancelPayment, component: CancelPayment },
  { path: blog, component: Blog },
  { path: blogDetails, component: BlogDetails },
  { path: blogPage, component: BlogPage },
  { path: referralLanding, component: ReferralLanding },
  { path: helpaPage, component: HelpaPage },
  { path: pay, component: Pay },
  { path: prohelpaLanding, component: ProhelpaLanding },
  { path: education, component: Education },
  { path: health, component: Health },
  { path: carPurchase, component: CarPurchase },
  { path: bills, component: Bills },
  { path: travel, component: Travel },
  { path: shopping, component: Shopping },
  { path: chooseAccount, component: ChooseAccount },
  { path: login, component: Login },
  { path: adminLogin, component: AdminLogin },
  { path: forgotPassword, component: ForgotPassword },
  { path: resetPassword, component: ResetPassword },
  { path: signupIndividual, component: SignupIndividual },
  { path: signupBusiness, component: SignupBusiness },
  { path: signupAgent, component: SignupAgent },
  { path: verificationSuccess, component: VerificationSuccess },
  { path: registrationSuccess, component: RegistrationSuccess },
  { path: businessRegistrationSuccess, component: BusinessRegistrationSuccess },
  { path: passwordresetSuccess, component: PasswordresetSuccess },
  { path: p2p, component: P2P },
  { path: wallet, component: Wallet },
  { path: settings, component: Settings },
  { path: businessUserSettings, component: BusinessUserSettings },
  { path: luStatus, component: LuStatus },
  { path: fuStatus, component: FuStatus },
  { path: referral, component: Referral },
  { path: accountVerification, component: AccountVerification },
  { path: identityVerification, component: IdentityVerification },
  { path: businessAccountVerification, component: BusinessAccountVerification },
  { path: agentAccountVerification, component: AgentAccountVerification },
  { path: onBoarding, component: OnBoarding },
  { path: onBoardingLocal, component: onBoardingLocal },
  { path: onBoardingHelpa, component: OnBoardingHelpa },
  { path: onBoardingProhelpa, component: OnBoardingProhelpa },
  { path: foreignHelpaP2P, component: ForeignHelpaP2P },
  { path: userOnboarding, component: UserOnboarding },
  { path: pageNotFound, component: PageNotFound },
];
