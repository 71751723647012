import * as signInTypes from "store/actions/accountVerification";

export const initialState = {
  currentStep: "",
  country: "",
  home_address: "",
  facial_pix: "",
  valid_id: "",
  id_number: "",
  phone: "",
  state_id: "",
  services: "",
};

const accountVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.data,
      };
    case signInTypes.ADD_PHONE:
      return {
        ...state,
        phone: action.data,
      };
    case signInTypes.ADD_SERVICES:
      return {
        ...state,
        services: action.data,
      };

    case signInTypes.SET_ADDRESS:
      return {
        ...state,
        state_id: action.data.state_id,
        home_address: action.data.address,
      };
    case signInTypes.ADD_FACIAL_PIC:
      return {
        ...state,
        facial_pix: action.data,
      };
    case signInTypes.ADD_ID:
      return {
        ...state,
        id_number: action.data.idNumber,
        valid_id: action.data.idCard,
      };
    case signInTypes.ADD_STEP:
      return {
        ...state,
        currentStep: action.data,
      };

    default:
      return state;
  }
};

export default accountVerificationReducer;
