import * as signInTypes from "store/actions/solicitation";

export const initialState = {
  solicitation_data: {},
};

const solicitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.SET_SOLICITATION:
      return {
        ...state,
        solicitation_data: { ...action.data },
      };

    default:
      return state;
  }
};

export default solicitationReducer;
