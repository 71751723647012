import React, { createContext, useState } from 'react';

export const AccountVerificationContext = createContext();

export const AccountVerificationProvider = ({ children }) => {
  const [accountVerification, setAccountVerification] = useState(false);

  return (
    <AccountVerificationContext.Provider
      value={{ accountVerification, setAccountVerification }}
    >
      {children}
    </AccountVerificationContext.Provider>
  );
};
