import { createReducer } from "@reduxjs/toolkit";
import * as signInTypes from "store/actions/p2p";

export const initialState = {
  helpa: "",
  helpa_id: "",
  helpaRate: "",
  isMyRate: false,
  offer_id: "",
  country_id: "",
  currency: "",
  currency_symbol: "",
  platforms: [],
  transactionDetails: {},
};

const p2pReducer = createReducer(initialState, (builder) => {
  builder.addCase(signInTypes.SET_PROHELPA, (state, action) => {
    return {
      ...state,
      helpa: action.data.helpa,
      helpa_id: action.data.helpa_id,
      helpaRate: action.data.rate,
      isMyRate: action.data.isMyRate,
      offer_id: action.data.offerId,
      country_id: action.data.country_id,
      currency: action.data.currency,
      currency_symbol: action.data.currency_symbol,
      platforms: action.data.platforms,
    };
  });
  builder.addCase(signInTypes.SET_TRANSACTION_DETAILS, (state, action) => {
    return {
      ...state,
      transactionDetails: { ...action.data },
    };
  });
});

export default p2pReducer;
