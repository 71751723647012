export const SET_PROHELPA = "SET_PROHELPA";
export const SET_TRANSACTION_DETAILS = "SET_TRANSACTION_DETAILS";
export const ADD_HELPA_RATE = "ADD_ID";
export const SET_IS_MY_RATE = "SET_IS_MY_RATE";

export const setProhelpa = (data) => ({
  type: SET_PROHELPA,
  data,
});

export const setTransactionDetails = (data) => ({
  type: SET_TRANSACTION_DETAILS,
  data,
});
