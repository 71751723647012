import Axios from 'axios';
import { createBrowserHistory } from 'history';

import store from 'store/store';
import { login } from 'userRoutes';
import { signOut } from 'store/actions/auth';
import { displayToast } from 'store/actions/toast';
import { pageNotFound } from 'userRoutes';

const axiosConfig = () => {
  const history = createBrowserHistory();
  // set axios base url
  Axios.defaults.baseURL = window['configs'].baseURL;
  // Request interceptor for API calls
  Axios.interceptors.request.use(
    (config) => {
      const token = store.getState().auth.token;
      // const token = localStorage.getItem("__tk");

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Adding a response interceptor
  Axios.interceptors.response.use(
    (response) => {
      if (response?.data?.status === 'Token is Expired') {
        console.log('Your session has expired');
        setTimeout(() => {
          store.dispatch(signOut(''));
          window.location.replace(login);
        }, 1000);
      } else {
        return response;
      }
    },
    (error) => {
      if (
        error?.response?.data?.message === 'Unauthenticated' ||
        error?.response?.status === 401
      ) {
        store.dispatch(
          displayToast({ message: 'Your session has expired', status: 'error' })
        );
        sessionStorage.clear();
        store.dispatch(signOut(''));
        history.push('/');
        window.location.reload();
        // setTimeout(() => {}, 1000);
      }

      // if (error?.response?.status === 404)
      //   window.location.replace(pageNotFound);

      store.dispatch(
        displayToast({
          message: error?.response?.data?.message,
          status: 'error',
        })
      );
      return Promise.reject(error);
    }
  );
};

export default axiosConfig;
