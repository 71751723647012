import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import routes, {
  home,
  carPurchase,
  health,
  shopping,
  education,
  bills,
  travel,
  about,
  careers,
  contact,
  faq,
  terms,
  privacy,
  chooseAccount,
  forgotPassword,
  resetPassword,
  login,
  signupIndividual,
  signupBusiness,
  registrationSuccess,
  verificationSuccess,
  passwordresetSuccess,
  referralLanding,
  businessRegistrationSuccess,
  businessAccountVerification,
  adminLogin,
  blog,
  blogDetails,
  identityVerification,
  agentAccountVerification,
  signupAgent,
  helpaPage,
  prohelpaLanding,
  pageNotFound,
  onBoardingHelpa,
  onBoardingProhelpa,
  checkout,
  paymentSuccess,
  cancelPayment,
  blogPage,
  pay,
  ambassadors,
} from 'userRoutes';
import store from 'store/store';
import RouteGuard from 'RouteGuard';
import AppViewImage from 'components/reusablecomponents/AppViewImage';
import ScrollToTop from 'components/common/ScrollToTop';
import LogOutToast from 'components/sharedComponents/LogOutToast';
import PageLoader from 'components/sharedComponents/PageLoader';
import PageNotFound from 'pages/user/PageNotFound';
import { AccountVerificationProvider } from 'contexts/AccountVerificationContext';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: (failureCount, error) => {
        switch (error.response?.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 405:
            return false;
          default:
            return failureCount < 1;
        }
      },
    },
  },
});

const unGuardedRoutes = [
  home,
  carPurchase,
  health,
  shopping,
  travel,
  bills,
  education,
  blog,
  blogDetails,
  blogPage,
  about,
  careers,
  ambassadors,
  contact,
  faq,
  terms,
  privacy,
  checkout,
  paymentSuccess,
  cancelPayment,
  chooseAccount,
  forgotPassword,
  resetPassword,
  login,
  adminLogin,
  signupIndividual,
  signupBusiness,
  signupAgent,
  registrationSuccess,
  identityVerification,
  businessRegistrationSuccess,
  passwordresetSuccess,
  verificationSuccess,
  onBoardingHelpa,
  onBoardingProhelpa,
  helpaPage,
  pay,
  prohelpaLanding,
  referralLanding,
  agentAccountVerification,
  businessAccountVerification,
  pageNotFound,
];

const helmetContext = {};

const AppUser = () => {
  return (
    <Provider store={store}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AppViewImage />
        <LogOutToast />

        <Router>
          <ScrollToTop />
          <Suspense fallback={<PageLoader showLoader />}>
            <HelmetProvider context={helmetContext}>
              <AccountVerificationProvider>
                <Switch>
                  {routes.map((route, i) => (
                    <Route key={i} exact path={route.path}>
                      {unGuardedRoutes.includes(route.path) ? (
                        <route.component />
                      ) : (
                        <RouteGuard path={route.path}>
                          {<route.component />}
                        </RouteGuard>
                      )}
                    </Route>
                  ))}
                  <Route path='*' component={PageNotFound} />
                </Switch>
              </AccountVerificationProvider>
            </HelmetProvider>
          </Suspense>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </ReactQueryCacheProvider>
    </Provider>
  );
};

export default AppUser;
