export const SET_COUNTRY = "SET_COUNTRY";
export const ADD_PHONE = "ADD_PHONE";
export const ADD_SERVICES = "ADD_SERVICES";
export const SET_ADDRESS = "SET_ADDRESS";
export const ADD_ID = "ADD_ID";
export const ADD_FACIAL_PIC = "ADD_FACIAL_PIC";
export const ADD_STEP = "ADD_STEP";

export const setCountry = (data) => ({
  type: SET_COUNTRY,
  data,
});
export const addPhone = (data) => ({
  type: ADD_PHONE,
  data,
});
export const addServices = (data) => ({
  type: ADD_SERVICES,
  data,
});

export const setAddress = (data) => ({
  type: SET_ADDRESS,
  data,
});

export const addId = (data) => ({
  type: ADD_ID,
  data,
});

export const addStep = (data) => ({
  type: ADD_STEP,
  data,
});
