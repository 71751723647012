import * as signInTypes from "store/actions/image";

export const initialState = {
  open: false,
  imageData: "",
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case signInTypes.OPEN_IMAGE:
      return {
        ...state,
        open: action.data.open,
        imageData: action.data.image,
      };

    default:
      return state;
  }
};

export default imageReducer;
