import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.css';
import AppUser from 'AppUser';
import reportWebVitals from './reportWebVitals';
import axiosConfig from 'configs/axiosConfig';
import store from 'store/store';
import { saveState } from 'utils';

ReactGA.initialize('UA-241968610-1');

ReactGA.pageview(window.location.pathname + window.location.search);

console.log(process.env.REACT_APP_GA_TRACKING_ID);

window.onbeforeunload = () => {
  sessionStorage.clear();
  if (store.getState().auth.user.id) {
    saveState(store.getState());
  }
};

axiosConfig();

ReactDOM.render(
  <React.StrictMode>
    <AppUser />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
