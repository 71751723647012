const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobile: 480,
  tablet: 768,
  laptopS: 1024,
  laptopL: 1200,
};

const device = {
  // mobileS: `(m-width: ${size.mobileS})`,
  // mobileM: `(min-width: ${size.mobileM})`,
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(min-width: ${size.mobile + 1}px) and (max-width: ${size.tablet}px)`,
  laptopS: `(min-width: ${size.tablet + 1}px  )`,
  laptopL: `(min-width: ${size.laptopS + 1}px)`,
  desktop: `(min-width: ${size.laptopL + 1}px)`,
};

export default device;
