import React, { useRef, useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FiDownload } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";

import img from "assets/non-svg/brand.png";
import { openImage } from "store/actions/image";
import { saveAs, isFileImage, getBase64 } from "utils";
import { color } from "theme";
import device from "configs/mediaQueries";

const { mobile } = device;

const AppViewImage = () => {
  const [inverse, setInverse] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [docUrl, setDocUrl] = useState();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { open, imageData } = useSelector((state) => state.image);

  useEffect(() => {
    if (imageData && open) {
      htmlToImage.toPng(imageRef.current).then(function (dataUrl) {
        setImageUrl(dataUrl);
      });
    }
  }, [open]);

  // const doc = new jsPDF({
  //   orientation: 'landscape'
  // });

  // const downloadAsPNG = () => saveAs(imageUrl && imageUrl, "image.png");
  // const downloadAsPDF = () => {
  //   doc.addImage(imageUrl && imageUrl, "JPEG", 0, -10, 300, 200);
  //   doc.save("image.pdf");
  // };

  const handleDownload = () => saveAs(imageUrl && imageUrl, "image.png");
  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text(imageUrl, 10, 10);
    doc.save(`${imageData.name}.pdf`);
  };

  // const handleDownload = isFileImage(imageUrl && image)
  //   ? downloadImage
  //   : downloadPdf;

  const handleClose = () => {
    setInverse(true);
    setTimeout(() => {
      dispatch(openImage({ open: false, imageData: "" }));
      setInverse(false);
    }, 400);
  };

  return (
    <>
      {open && (
        <Container inverse={inverse}>
          <HeaderDiv>
            <FiDownload
              color={color.white}
              size={isTabletOrMobile ? "1.5rem" : "2.5rem"}
              style={{ cursor: "pointer" }}
              onClick={handleDownload}
            />
            <MdOutlineCancel
              color={color.white}
              size={isTabletOrMobile ? "1.5rem" : "2.2rem"}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </HeaderDiv>
          <ImageDiv>
            <Img src={imageData} ref={imageRef} />
          </ImageDiv>
        </Container>
      )}
    </>
  );
};

export default AppViewImage;

const animate = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10rem)  scale(0);
  }
 
  100% {
    opacity: 1;
    transform: translateY(0rem) scale(1);
    
  }
`;
const animateInverse = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0rem) scale(1);
  }
 
  100% {
    opacity: 0;
    transform: translateY(10rem)  scale(0);
    
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) => {
    if (props.inverse) {
      return css`
        animation: ${animateInverse} 0.4s linear 1 normal;
      `;
    } else {
      return css`
        animation: ${animate} 0.4s linear 1 normal;
      `;
    }
  }}
  @media ${mobile} {
    padding: 1rem;
  }
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 1rem;
  position: absolute;
  top: 0;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
  @media ${mobile} {
    padding: 1rem 0.5rem;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  height: 70%;
  width: 70%;
  @media ${mobile} {
    width: 100%;
    margin-top: 0;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  align-self: center;
`;
