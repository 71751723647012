export default {
  brand1: "#F0F4FF",
  brand2: "#3E7BFA",
  brand3: "#222C65",
  brand4: "#4577BC",
  brand5: " #4577BC",
  brand6: "#16BBF8",
  fontDark: "#333",
  dark: "#4d4d4d",
  borderGrey: "#dadada",
  fontLight: "#979797",
  // black: "#231F20",
  brandPurple: "#6D1ED4",
  black: "#121212",
  white: "#fff",
  green: "#0ECB81",
  brandYellow: "#FBA229",
  danger: "#FF1F00",
  bgColor: "#fcfcfc",
};
