import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import sadImg from "assets/svg/sad.svg";
import { color } from "theme";
import { home } from "userRoutes";
import AppButton from "components/reusablecomponents/AppButton";

const PageNotFound = () => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <Container>
      <img src={sadImg} />
      <Typography
        variant="subtitle2"
        component="h2"
        fontWeight={700}
        fontFamily={"Raleway"}
        fontSize={isTabletOrMobile ? "34px" : "54px"}
        marginTop={"3rem"}
      >
        Sorry, page not found
      </Typography>
      <Typography
        variant="subtitle2"
        component="h2"
        fontWeight={400}
        fontFamily={"Raleway"}
        fontSize={isTabletOrMobile ? "18px" : "24px"}
        marginBottom={"1.5rem"}
      >
        Check out other page on our website
      </Typography>
      <AppButton
        btnText={"BACK TO HOME PAGE"}
        padding={"16px 25px"}
        bgColor={color.brand5}
        borderRadius={"5px"}
        onClick={() => history.push(home)}
      />
    </Container>
  );
};

export default PageNotFound;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
