import { combineReducers } from "redux";
import accountVerificationReducer from "./accountVerificationReducer";
import authReducer from "./authReducer";
import imageReducer from "./imageReducer";
import p2pReducer from "./p2pReducer";
import solicitationReducer from "./solicitationReducer";
import toastReducer from "./toastReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  accountVerification: accountVerificationReducer,
  solicitation: solicitationReducer,
  p2p: p2pReducer,
  image: imageReducer,
  toast: toastReducer,
});

export default rootReducer;
