import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FaTimesCircle, FaTimes } from "react-icons/fa";
import { IoCheckmarkCircle, IoWarning } from "react-icons/io5";

import { displayToast } from "store/actions/toast";
import device from "configs/mediaQueries";

const { mobile } = device;

const colorType = (status) => {
  if (status === "success") return "green";
  if (status === "error") return "red";
  if (status === "warning");
  return null;
};
const textType = (status) => {
  return status;
};

const IconType = (status) => {
  if (status === "success") return <IoCheckmarkCircle color="green" />;
  if (status === "error") return <FaTimesCircle color="red" />;
  if (status === "warning") return <IoWarning color="yellow" />;
  return null;
};

const LogOutToast = () => {
  const [removeToast, setRemoveToast] = useState(false);
  const { status, message } = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setRemoveToast(true);
      }, 2400);
      setTimeout(() => {
        dispatch(displayToast({ status: "", message: "" }));
        setRemoveToast(false);
      }, 2700);
    }
  }, [message]);
  return (
    <>
      {message && (
        <Container>
          <Div
            className="p-3 my-2 rounded"
            remove={removeToast}
            isMobile={isTabletOrMobile}
          >
            <Toast>
              <ToastHeader icon={IconType(status)}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "19rem",
                  }}
                >
                  <span
                    style={{
                      color: colorType(status),
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {textType(status)}
                  </span>

                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      dispatch(displayToast({ status: "", message: "" }))
                    }
                  >
                    <FaTimes />
                  </span>
                </Box>
              </ToastHeader>
              <ToastBody>
                <span
                  style={{
                    color: colorType(status),
                    fontWeight: "600",
                  }}
                >
                  {message}
                </span>
              </ToastBody>
            </Toast>
          </Div>
        </Container>
      )}
    </>
  );
};

export default LogOutToast;

const Container = styled.div`
  position: fixed;
  width: 40vw;
  height: 40vh;
  right: 0;
  top: 0;
  z-index: 2000;
  @media ${mobile} {
    width: 100%;
  }
`;

const showToastMobile = keyframes`
0% {
  opacity: 0;
  transform: translateX(0rem);
}
100% {
  opacity: 1;
  transform: translateX(.7rem);
  
}
`;

const showToast = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0rem);
  }
  100% {
    opacity: 1;
    transform: translateX(-4rem);
    
  }
`;
const removeToast = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-4rem);
  }
  30% {
    opacity: 1;
    transform: translateX(-13rem);
  }
  100% {
    opacity: 0;
    transform: translateX(0rem);
  }
`;

const Div = styled.div`
  position: absolute;

  top: 0;
  right: 0;
  ${(props) => {
    if (props.remove) {
      return css`
        animation: ${removeToast} 0.3s linear 1 alternate;
      `;
    } else {
      return css`
        animation: ${props.isMobile ? showToastMobile : showToast} 0.3s linear 1
          alternate;
      `;
    }
  }}
  animation-fill-mode: forwards;
`;
